<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Pekerjaan </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputPekerjaan">
              <CRow>
                <CCol sm="6">
                  <div class="form-group">
                    <label> Tahun Anggaran</label>
                    <select
                      v-model="tahun_anggaran"
                      placeholder="Pilih Tahun"
                      class="form-control"
                    >
                      <option value="2022" selected>2022</option>
                      <option value="2021" >2021</option>
                      <option value="2020">2020</option>
                    </select>
                  </div>
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label> Nama Kegiatan</label>
                    <select
                      v-model="nama_kegiatan"
                      placeholder="Pilih Nama"
                      class="form-control"
                    >
                      <option value="" disabled selected>Pilih Kegiatan</option>
                      <option v-for="dt in jenis" :value="dt.nama" v-bind:key="dt.nama">
                        {{ dt.nama }}
                      </option>
                    </select>
                  </div>
                  <!-- <CSelect
                  label="Nama Kegiatan"
                  v-model="nama_kegiatan"
                  :options="jenis"
                  placeholder="Pilih Kegiatan"
              /> -->
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <div class="form-group">
                    <label> Sub Kegiatan</label>
                    <select
                      v-model="sub_kegiatan"
                      placeholder="Pilih Nama"
                      class="form-control"
                    >
                      <option value="" disabled selected>
                        Pilih Sub Kegiatan
                      </option>
                      <option
                        v-for="dt in subjenis"
                        :value="dt.nama"
                        v-bind:key="dt.nama"
                      >
                        {{ dt.nama }}
                      </option>
                    </select>
                  </div>
                  <!-- <CSelect
                  v-model="sub_kegiatan"
                  label="Sub Kegiatan"
                  :options="subjenis"
                  placeholder="Pilih Sub Kegiatan"
              /> -->
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="nama_pekerjaan"
                    label="Pekerjaan"
                    placeholder="Input Nama pekerjaan"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="lokasi"
                    label="Lokasi"
                    placeholder="Input lokasi pekerjaan"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="koordinat"
                    @click="mapModal = true"
                    label="Koordinat Pekerjaan"
                    placeholder="Klik untuk memilih peta koordinat"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="kontraktor"
                    label="Jasa Penyedia"
                    placeholder="Input nama kontraktor"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="konsultan_supervisi"
                    label="Konsultan Supervisi"
                    placeholder="Input konsultan supervisi"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="nilai_pagu"
                    label="Nilai Pagu"
                    v-bind="money"
                    placeholder="Input nilai pagu"
                  />
                  <!-- <currency-input
                label="Nilai Pagu"
                placeholder="Input nilai pagu"
                v-model="nilai_pagu"
                currency="EUR"
                locale="de"
              /> -->
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="nilai_kontrak"
                    label="Nilai Kontrak"
                    v-bind="money"
                    placeholder="Input Nilai Kontrak"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="nomor_kontrak"
                    label="Nomor Kontrak"
                    placeholder="Input nomor kontrak"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type='date'
                    v-model="tgl_kontrak"
                    label="Tgl Kontrak"
                    placeholder="Input tgl kontrak"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type='date'
                    v-model="tgl_mulai"
                    label="Tgl Mulai Pekerjaan"
                    placeholder="Input Tgl Mulai"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type='date'
                    v-model="tgl_selesai"
                    label="Tgl Selesai Pekerjaan"
                    placeholder="Input Tgl Selesai"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="panjang"
                    label="Panjang Penanganan"
                    placeholder="Input panjang"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="lebar"
                    label="Lebar"
                    placeholder="Input lebar"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="jumlah_hari_pelaksanaan"
                    label="Jumlah Hari Pelaksanaan"
                    placeholder="Input Jumlah Hari Pelaksanaan"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="pptk"
                    label="PPTK"
                    placeholder="Input PPTK"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="pengawas1"
                    label="Pengawas 1"
                    placeholder="Input pengawas 1"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="pengawas2"
                    label="Pengawas 2"
                    placeholder="Input pengawas 2"
                  />
                </CCol>
              </CRow>
              <div class="form-actions">
                <CButton
                  type="submit"
                  color="primary"
                  style="margin-right: 15px"
                  >Simpan</CButton
                >
                <CButton color="secondary">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal title="Error Input" color="danger" id="modalError">
      <b>Error</b>! Data pekerjaan gagal diinput.
    </CModal>

    <CModal
      title="Pilih Koordinat"
      size="lg"
      id="mapModal"
      :show.sync="mapModal"
    >
      <img
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 10000;
          margin-left: -25px;
          margin-top: -50px;
          height: 50px;
          width: 50px;
          cursor: pointer;
        "
        :src="require('../../assets/images/marker.png')"
      />
      <input type="hidden" v-model="koordinat" />
      <gmap-map
        :zoom="14"
        :center="center"
        ref="mapRef"
        id="g-map"
        style="width: 100%; height: 300px"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in locationMarkers"
          :position="m.position"
          @click="openMarker(m.id)"
        >
          <gmap-info-window
            :closeclick="true"
            @closeclick="openMarker(null)"
            :opened="openedMarkerID === m.id"
          >
            <div>{{ m.name }}</div>
          </gmap-info-window>
        </gmap-marker>
      </gmap-map>
    </CModal>
  </div>
</template>
<style>
.centerMarker {
  position: absolute;
  /*url of the marker*/
  /*center the marker*/
  top: 50%;
  left: 50%;

  z-index: 1;
  /*fix offset when needed*/
  margin-left: -10px;
  margin-top: -10px;
  /*size of the image*/
  height: 20px;
  width: 20px;

  cursor: pointer;
}
</style>
<script>
import axios from "axios";
import * as Modals from "../notifications/Modals.vue";
import {mask} from 'vue-the-mask'
import {Money} from 'v-money'

// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  components: {Money},
  directives: {mask},
  name: "Forms",
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2019 },
        (value, index) => new Date().getFullYear() - index
      );
    },
  },
  data() {
    return {
      center: {
        lat: 0.8851261,
        lng: 108.9814298,
      },
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 0,
          masked: true
      },
      mapModal: false,
      openedMarkerID: null,
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      tahun_anggaran: "",
      nama_kegiatan: "",
      sub_kegiatan: "",
      nama_pekerjaan: "",
      lokasi: "",
      kontraktor: "",
      konsultan_supervisi: "",
      nilai_kontrak: "",
      nilai_pagu: "",
      koordinat: "",
      panjang: "",
      lebar: "",
      nomor_kontrak: "",
      tgl_kontrak: "",
      tgl_mulai: "",
      tgl_selesai: "",
      jumlah_hari_pelaksanaan:"",
      pptk:"",
      pengawas1:"",
      pengawas2:"",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label: "col-3", input: "col-9" },
      options: ["Option 1", "Option 2", "Option 3"],
      jenis: [],
      subjenis: [],
      tahuns: ["2021", "2020"],
      selectOptions: [
        "Option 1",
        "Option 2",
        "Option 3",
        {
          value: "some value",
          label: "Selected option",
        },
      ],
      selectedOption: "some value",

      formCollapsed: true,
      checkboxNames: [
        "Checkboxes",
        "Inline Checkboxes",
        "Checkboxes - custom",
        "Inline Checkboxes - custom",
      ],
      radioNames: [
        "Radios",
        "Inline Radios",
        "Radios - custom",
        "Inline Radios - custom",
      ],
      map : null
    };
  },
  mounted() {
    this.loadData();
      // const google = await gmapsInit()
      // this.map = new google.maps.Map(
      //   document.getElementById('g-map'),
      //   this.mapOptions
      // )
      // const that = this;

      // this.map = new google.maps.Map(document.getElementById('g-map'));
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.addListener("dragend", (e) => {
          // 3 seconds after the center of the map has changed, pan back to the
          // marker.
          // function call not working
          // alert( "Latitude: "+map.getCenter().lat()+" "+", longitude: "+map.getCenter().lng()); 
          // alert(e.latLng.lat()+","+ e.latLng.lng());
          this.koordinat = map.getCenter().lat()+","+ map.getCenter().lng();
        });
        // map.addListener(this.map, "dragend", (e) => {
        //   // function call not working
        //   alert("drag");
        //   this.koordinat = (e.latLng.lat(), e.latLng.lng());
        // });
      });
    // this.getCenter();
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },\
    loadData(){
      axios.get("https://sippd.probowsolution.com/public/kegiatan")
            .then((response3) => {

                  this.jenis = response3.data;
                
            });

          axios.get("https://sippd.probowsolution.com/public/subkegiatan")
            .then((response2) => {

                  this.subjenis = response2.data;
                
            });
    },
    async getCenter() {
      // const google = await gmapsInit();
      // this.map = new google.maps.Map(
      //   document.getElementById("g-map"),
      //   this.mapOptions
      // );
      const google = await gmapsInit()
      this.map = new google.maps.Map(
        document.getElementById('g-map'),
        this.mapOptions
      )
      // const that = this;

      this.map = new google.maps.Map(document.getElementById('g-map'));
      // this.$refs.mapRef.$mapPromise.then((map) => {
        google.maps.event.addListener(this.map, "dragend", (e) => {
          // function call not working
          alert("drag");
          this.koordinat = (e.latLng.lat(), e.latLng.lng());
        });
      // });
    },
    addMarker(latt, long) {
      // if (this.existingPlace) {
      // const marker = {
      //   lat: this.existingPlace.geometry.location.lat(),
      //   lng: this.existingPlace.geometry.location.lng()
      // };

      const marker = {
        lat: +latt,
        lng: +long,
      };
      this.locationMarkers.push({ position: marker });
      this.locPlaces.push(this.existingPlace);
      // this.center = marker;
      // this.existingPlace = null;
      // }
    },
    // getCenter() {},
    inputPekerjaan: function (event) {
      // Simple POST request with a JSON body using axios
      const pekerjaan = {
        tahun_anggaran: this.tahun_anggaran,
        nama_kegiatan: this.nama_kegiatan,
        sub_kegiatan: this.sub_kegiatan,
        nama_pekerjaan: this.nama_pekerjaan,
        lokasi: this.lokasi,
        koordinat: this.koordinat,
        kontraktor: this.kontraktor,
        konsultan_supervisi: this.konsultan_supervisi,
        nilai_pagu: this.nilai_pagu,
        nilai_kontrak: this.nilai_kontrak,
        tgl_kontrak: this.tgl_kontrak,
        tgl_mulai: this.tgl_mulai,
        tgl_selesai: this.tgl_selesai,
        panjang: this.panjang,
        lebar: this.lebar,
        jumlah_hari_pelaksanaan: this.jumlah_hari_pelaksanaan,
        pptk: this.pptk,
        pengawas1: this.pengawas1,
        pengawas2: this.pengawas2,
      };
      alert(JSON.stringify(pekerjaan));
      axios
        .post(
          "https://sippd.probowsolution.com/public/addpekerjaan",
          pekerjaan
        )
        .then((response) => {
          console.log(response.data);
          //alert(response.data);
          if (response.data == "sukses") {
            this.$router.push("/pekerjaan");
          } else {
            this.$root.$emit("bv::show::modal", "modalError");
          }
        });
    },
  },
};
</script>
